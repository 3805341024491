html {
  padding: env(safe-area-inset);
  height: 100%;
  width: 100vw;
  background-color: #553f28;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}
